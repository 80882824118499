



































































































































































































































































































































import TopBar from "@/components/common/TopBar.vue";
import Editor from "@/components/common/Editor.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { getRouter } from "../../router";
import api from "../../api";
import store from "../../store";
import { Watch } from "vue-property-decorator";
import { callBilanzEditor } from "../../helper/utils";
declare const $: any;

@Component({ components: { TopBar, Editor } })
export default class BilanzEditor extends Vue {
  routerName: any = "";
  showJson = false;
  prevId = 0;
  templateType = 0;
  templateYear = 2015;
  isBilanz = false;
  prev_mandator: any;
  prev_year: any;
  templateName = "";
  templateSelected = false;
  selectedTemplate: any = "";
  editorTitle = "";
  messageStatus = 0;

  constructor() {
    super();
    this.routerName = getRouter().currentRoute.name;
    if (this.routerName.indexOf("bilanz") > -1) {
      this.editorTitle = "Bilanz Struktur Template Editor";
    } else {
      this.editorTitle = "Gewinn- und Verlusrechnung Struktur Template Editor";
    }
  }

  get pageData() {
    return {
      clients: store.getters.clients,
      years: store.getters.years,
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
    };
  }

  get templates() {
    return store.getters.bilanzTemplates;
  }

  get templateData() {
    return store.getters.bilanzTemplateData;
  }

  mounted() {
    this.initSortable();
    this.onTopBarDataChange();
  }

  get topBarData() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
    };
  }

  @Watch("topBarData")
  onTopBarDataChange() {
    if (
      getRouter().currentRoute.name === "bilanz_editor_uknow" ||
      getRouter().currentRoute.name === "guv_editor_admin"
    ) {
      if (!store.getters.isBilanzEditorCalled) {
        callBilanzEditor();
      }
    } else {
      if (this.topBarData.year && this.topBarData.client) {
        if (!store.getters.isBilanzEditorCalled) {
          callBilanzEditor();
        }
      }
    }
  }

  @Watch("templateData")
  onTemplateDataChange() {
    this.prevId = 0;
    if (this.templateData) {
      this.getId(this.templateData);
      this.templateSelected = true;
    } else {
      this.templateSelected = false;
    }
  }

  loadTemplate() {
    this.messageStatus = 0;
    this.templateSelected = true;
    this.prevId = 0;
    store.dispatch("updateBilanzTemplateData", null);
    if (this.routerName === "bilanz_editor_uknow") {
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .getUknowTemplate(this.templateName)
        .then((res) => {
          if (res.data) {
            this.getId(res.data);
            store.dispatch("updateBilanzTemplateData", res.data);
          } else {
            store.dispatch("updateBilanzTemplateData", []);
          }
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateBilanzTemplateData", []);
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    } else if (this.routerName === "guv_editor_admin") {
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .getGuvAdminTemplate(this.templateName)
        .then((res) => {
          if (res.data) {
            this.getId(res.data);
            store.dispatch("updateBilanzTemplateData", res.data);
          } else {
            store.dispatch("updateBilanzTemplateData", []);
          }
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateBilanzTemplateData", []);
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    } else if (this.routerName === "bilanz_editor_kanzlei") {
      if (this.pageData.clients) {
        store.dispatch(
          "updateLoading",
          store.state.loading >= 0 ? store.state.loading + 1 : 1
        );
        api
          .getKanzleiTemplate(this.pageData.clients[0], this.templateName)
          .then((res) => {
            if (res.data) {
              this.getId(this.templateData);
              store.dispatch("updateBilanzTemplateData", res.data);
            } else {
              store.dispatch("updateBilanzTemplateData", []);
            }
            store.dispatch("updateLoading", store.state.loading - 1);
          })
          .catch((err) => {
            store.dispatch("updateBilanzTemplateData", []);
            store.dispatch("updateLoading", store.state.loading - 1);
          });
      }
    } else if (this.routerName === "guv_editor_kanzlei") {
      if (this.pageData.clients) {
        store.dispatch(
          "updateLoading",
          store.state.loading >= 0 ? store.state.loading + 1 : 1
        );
        api
          .getGuvKanzleiTemplate(this.pageData.clients[0], this.templateName)
          .then((res) => {
            if (res.data) {
              this.getId(this.templateData);
              store.dispatch("updateBilanzTemplateData", res.data);
            } else {
              store.dispatch("updateBilanzTemplateData", []);
            }
            store.dispatch("updateLoading", store.state.loading - 1);
          })
          .catch((err) => {
            store.dispatch("updateBilanzTemplateData", []);
            store.dispatch("updateLoading", store.state.loading - 1);
          });
      }
    }
  }

  initSortable() {
    ($("ol.sortable") as any).nestedSortable({
      forcePlaceholderSize: true,
      handle: "div",
      helper: "clone",
      items: "li",
      opacity: 0.6,
      placeholder: "placeholder",
      revert: 250,
      tabSize: 25,
      tolerance: "pointer",
      toleranceElement: "> div",
      maxLevels: 10,
      isTree: true,
      expandOnHover: 700,
      startCollapsed: false,
      change: function() {},
    });
  }

  importJson() {
    this.messageStatus = 0;
    store.dispatch("updateBilanzTemplateData", null);
    setTimeout(() => {
      try {
        if (JSON.parse($(".json-area").val())) {
          this.prevId = 0;
          store.dispatch(
            "updateBilanzTemplateData",
            JSON.parse($(".json-area").val())
          );
          this.getId(JSON.parse($(".json-area").val()));
        }
      } catch (e) {
        alert("Import correct JSON");
      }
    }, 500);
  }

  getId(items: any) {
    if (!items) {
      return;
    }
    for (var i = 0; i < items.length; i++) {
      if (this.prevId < parseInt(items[i].id)) {
        this.prevId = parseInt(items[i].id);
      }
      if (items[i].subitems) {
        this.getId(items[i].subitems);
      }
    }
  }

  addItem() {
    this.messageStatus = 0;
    this.prevId += 1;
    this.templateData.push({
      id: this.prevId,
      label: "",
      extConfig: { collaped: true },
    });
  }

  saveJson() {
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    var json = $("ol.sortable").nestedSortable("toHierarchy", {
      startDepthCount: 0,
    });
    if (this.routerName === "bilanz_editor_uknow") {
      api
        .saveUknowBilanz(this.templateName, json)
        .then((res) => {
          this.messageStatus = 1;
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          this.messageStatus = -1;
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    } else if (this.routerName === "guv_editor_admin") {
      api
        .saveGuvAdmin(this.templateName, json)
        .then((res) => {
          this.messageStatus = 1;
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          this.messageStatus = -1;
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    } else if (this.routerName === "bilanz_editor_kanzlei") {
      if (this.pageData.clients) {
        api
          .saveKanzleiBilanz(this.pageData.clients[0], this.templateName, json)
          .then((res) => {
            this.messageStatus = 1;
            store.dispatch("updateLoading", store.state.loading - 1);
          })
          .catch((err) => {
            this.messageStatus = -1;
            store.dispatch("updateLoading", store.state.loading - 1);
          });
      }
    } else if (this.routerName === "guv_editor_kanzlei") {
      if (this.pageData.clients) {
        api
          .saveGuvKanzlei(this.pageData.clients[0], this.templateName, json)
          .then((res) => {
            this.messageStatus = 1;
            store.dispatch("updateLoading", store.state.loading - 1);
          })
          .catch((err) => {
            this.messageStatus = -1;
            store.dispatch("updateLoading", store.state.loading - 1);
          });
      }
    } else if (this.routerName === "bilanz_editor_mandant") {
      api
        .deleteMandantBilanz(this.pageData.client, this.pageData.year)
        .then((res) => {
          api
            .saveMandantBilanz(this.pageData.client, this.pageData.year, json)
            .then((res) => {
              this.messageStatus = 1;
              this.templateSelected = true;
              store.dispatch("updateLoading", store.state.loading - 1);
            })
            .catch((err) => {
              this.messageStatus = -1;
              store.dispatch("updateLoading", store.state.loading - 1);
            });
        })
        .catch((err) => {
          this.messageStatus = -1;
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    } else if (this.routerName === "guv_editor_mandant") {
      api
        .deleteGuvMandant(this.pageData.client, this.pageData.year)
        .then((res) => {
          api
            .saveGuvMandant(this.pageData.client, this.pageData.year, json)
            .then((res) => {
              this.messageStatus = 1;
              this.templateSelected = true;
              store.dispatch("updateLoading", store.state.loading - 1);
            })
            .catch((err) => {
              this.messageStatus = -1;
              store.dispatch("updateLoading", store.state.loading - 1);
            });
        })
        .catch((err) => {
          this.messageStatus = -1;
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }

  deleteTemplate() {
    if (this.routerName === "bilanz_editor_uknow") {
      api.deleteUknowBilanz(this.templateName).then((res) => {
        this.templateSelected = false;
        api
          .getUknowBilanzTemplates()
          .then((res) => {
            store.dispatch("updateBilanzTemplates", res.data);
            store.dispatch("updateBilanzTemplateData", []);
            this.templateName = "";
            this.prevId = 0;
          })
          .catch((err) => {
            store.dispatch("updateBilanzTemplates", null);
            store.dispatch("updateBilanzTemplateData", []);
          });
      });
    } else if (this.routerName === "guv_editor_admin") {
      api.deleteGuvAdmin(this.templateName).then((res) => {
        this.templateSelected = false;
        api
          .getGuvAdminTemplates()
          .then((res) => {
            store.dispatch("updateBilanzTemplates", res.data);
            store.dispatch("updateBilanzTemplateData", []);
            this.templateName = "";
            this.prevId = 0;
          })
          .catch((err) => {
            store.dispatch("updateBilanzTemplates", null);
            store.dispatch("updateBilanzTemplateData", []);
          });
      });
    } else if (this.routerName === "bilanz_editor_kanzlei") {
      if (this.pageData.clients) {
        api
          .deleteKanzleiBilanz(this.pageData.clients[0], this.templateName)
          .then((res) => {
            this.templateSelected = false;
            api
              .getKanzleiBilanzTemplates(this.pageData.clients[0])
              .then((res) => {
                store.dispatch("updateBilanzTemplates", res.data);
                store.dispatch("updateBilanzTemplateData", []);
                this.templateName = "";
                this.prevId = 0;
              })
              .catch((err) => {
                store.dispatch("updateBilanzTemplates", null);
                store.dispatch("updateBilanzTemplateData", []);
              });
          });
      }
    } else if (this.routerName === "guv_editor_kanzlei") {
      if (this.pageData.clients) {
        api
          .deleteGuvKanzlei(this.pageData.clients[0], this.templateName)
          .then((res) => {
            this.templateSelected = false;
            api
              .getGuvKanzleiTemplates(this.pageData.clients[0])
              .then((res) => {
                store.dispatch("updateBilanzTemplates", res.data);
                store.dispatch("updateBilanzTemplateData", []);
                this.templateName = "";
                this.prevId = 0;
              })
              .catch((err) => {
                store.dispatch("updateBilanzTemplates", null);
                store.dispatch("updateBilanzTemplateData", []);
              });
          });
      }
    } else if (this.routerName === "bilanz_editor_mandant") {
      api
        .deleteMandantBilanz(this.pageData.client, this.pageData.year)
        .then((res) => {
          this.templateSelected = false;
          store.dispatch("updateBilanzTemplateData", []);
          this.prevId = 0;
        })
        .catch((err) => {
          store.dispatch("updateBilanzTemplateData", []);
        });
    } else if (this.routerName === "guv_editor_mandant") {
      api
        .deleteGuvMandant(this.pageData.client, this.pageData.year)
        .then((res) => {
          this.templateSelected = false;
          store.dispatch("updateBilanzTemplateData", []);
          this.prevId = 0;
        })
        .catch((err) => {
          store.dispatch("updateBilanzTemplateData", []);
        });
    }
  }

  exportJson() {
    var json = $("ol.sortable").nestedSortable("toHierarchy", {
      startDepthCount: 0,
    });
    localStorage.setItem("templateJSON", JSON.stringify(json));
    $(".json-area").val(JSON.stringify(json));
  }

  setTemplateType(type: any) {
    this.templateType = type;
    this.selectedTemplate = "";
    if (type === 1) {
      if (this.routerName === "bilanz_editor_mandant") {
        api
          .getUknowBilanzTemplates()
          .then((res) => {
            store.dispatch("updateBilanzTemplates", res.data);
          })
          .catch((err) => {
            store.dispatch("updateBilanzTemplates", null);
          });
      } else {
        api
          .getGuvAdminTemplates()
          .then((res) => {
            store.dispatch("updateBilanzTemplates", res.data);
          })
          .catch((err) => {
            store.dispatch("updateBilanzTemplates", null);
          });
      }
    } else if (type === 2) {
      if (this.routerName === "bilanz_editor_mandant") {
        api
          .getKanzleiBilanzTemplates(this.pageData.clients[0])
          .then((res) => {
            store.dispatch("updateBilanzTemplates", res.data);
          })
          .catch((err) => {
            store.dispatch("updateBilanzTemplates", null);
          });
      } else {
        api
          .getGuvKanzleiTemplates(this.pageData.clients[0])
          .then((res) => {
            store.dispatch("updateBilanzTemplates", res.data);
          })
          .catch((err) => {
            store.dispatch("updateBilanzTemplates", null);
          });
      }
    }
  }

  reloadTemplate() {
    this.messageStatus = 0;
    store.dispatch("updateBilanzTemplateData", []);
    this.prevId = 0;
    if (!this.selectedTemplate) {
      return;
    }
    if (this.templateType === 0) {
      if (this.routerName === "bilanz_editor_mandant") {
        api
          .getMandantTemplate(this.pageData.client, this.selectedTemplate)
          .then((res) => {
            if (res.data) {
              this.getId(res.data);
              store.dispatch("updateBilanzTemplateData", res.data);
            }
          })
          .catch((err) => {
            store.dispatch("updateBilanzTemplateData", []);
          });
      } else {
        api
          .getGuvMandant(this.pageData.client, this.selectedTemplate)
          .then((res) => {
            if (res.data) {
              this.getId(res.data);
              store.dispatch("updateBilanzTemplateData", res.data);
            }
          })
          .catch((err) => {
            store.dispatch("updateBilanzTemplateData", []);
          });
      }
    } else if (this.templateType === 1) {
      if (this.routerName === "bilanz_editor_mandant") {
        api
          .getUknowTemplate(this.selectedTemplate)
          .then((res) => {
            if (res.data) {
              this.getId(res.data);
              store.dispatch("updateBilanzTemplateData", res.data);
            }
          })
          .catch((err) => {
            store.dispatch("updateBilanzTemplateData", []);
          });
      } else {
        api
          .getGuvAdminTemplate(this.selectedTemplate)
          .then((res) => {
            if (res.data) {
              this.getId(res.data);
              store.dispatch("updateBilanzTemplateData", res.data);
            }
          })
          .catch((err) => {
            store.dispatch("updateBilanzTemplateData", []);
          });
      }
    } else if (this.templateType === 2) {
      if (this.routerName === "bilanz_editor_mandant") {
        api
          .getKanzleiTemplate(this.pageData.client, this.selectedTemplate)
          .then((res) => {
            if (res.data) {
              this.getId(res.data);
              store.dispatch("updateBilanzTemplateData", res.data);
            }
          })
          .catch((err) => {
            store.dispatch("updateBilanzTemplateData", []);
          });
      } else {
        api
          .getGuvKanzleiTemplate(this.pageData.client, this.selectedTemplate)
          .then((res) => {
            if (res.data) {
              this.getId(res.data);
              store.dispatch("updateBilanzTemplateData", res.data);
            }
          })
          .catch((err) => {
            store.dispatch("updateBilanzTemplateData", []);
          });
      }
    }
  }
}
