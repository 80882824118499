



































































































declare const $: any;
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { getRouter } from "../../router";

@Component({ name: "Editor" })
export default class Editor extends Vue {
  @Prop() item!: any;
  routerName: any = "";

  constructor() {
    super();
    this.routerName = getRouter().currentRoute.name;
  }

  getAccountText() {
    let accountsText = "";
    if (this.item.accounts) {
      for (var i = 0; i < this.item.accounts.length; i++) {
        if (this.item.accounts[i][0] == this.item.accounts[i][1]) {
          accountsText += this.item.accounts[i][0];
        } else {
          accountsText +=
            this.item.accounts[i][0] + "-" + this.item.accounts[i][1];
        }
        if (i != this.item.accounts.length - 1) {
          accountsText += ",";
        }
      }
    }
    return accountsText;
  }

  collapse() {
    $("#item_" + this.item.id)
      .toggleClass("mjs-nestedSortable-expanded")
      .toggleClass("mjs-nestedSortable-collapsed");
  }

  enableAccounts(e: any) {
    if (this.routerName === "bilanz_editor_mandant") {
      if (e.target.checked) {
        $("#item_" + this.item.id + ">.menuDiv .accounts").prop(
          "disabled",
          false
        );
      } else {
        $("#item_" + this.item.id + ">.menuDiv .accounts").prop(
          "disabled",
          true
        );
        $("#item_" + this.item.id + ">.menuDiv .accounts").val("");
      }
      $("#item_" + this.item.id + ">.menuDiv .fixed_value").prop(
        "disabled",
        true
      );
      $("#item_" + this.item.id + ">.menuDiv .fixed_value").val("");
      $("#item_" + this.item.id + ">.menuDiv .showFixed").prop(
        "checked",
        false
      );
    } else {
      $("#item_" + this.item.id + ">.menuDiv .accounts").prop(
        "disabled",
        !e.target.checked
      );
    }
  }

  deleteItem() {
    $("#item_" + this.item.id).remove();
  }

  enableCustom(e: any) {
    if (e.target.checked) {
      $("#item_" + this.item.id + ">.menuDiv .fixed_value").prop(
        "disabled",
        false
      );
    } else {
      $("#item_" + this.item.id + ">.menuDiv .fixed_value").prop(
        "disabled",
        true
      );
      $("#item_" + this.item.id + ">.menuDiv .fixed_value").val("");
    }
    $("#item_" + this.item.id + ">.menuDiv .accounts").prop("disabled", true);
    $("#item_" + this.item.id + ">.menuDiv .showVal").prop("checked", false);
    $("#item_" + this.item.id + ">.menuDiv .accounts").val("");
  }

  disablePositive(e: any) {
    if (e.target.checked) {
      $("#item_" + this.item.id + ">.menuDiv .show_only_if_negative").prop(
        "checked",
        false
      );
    }
  }

  disableNegative(e: any) {
    if (e.target.checked) {
      $("#item_" + this.item.id + ">.menuDiv .show_only_if_positive").prop(
        "checked",
        false
      );
    }
  }
}
